import Analytics from 'admin/services/analytics';
import BaseRoute from 'admin/routes/base-route';
import Store from '@ember-data/store';
import { ModelFrom } from 'admin/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class LegalIndividualWireInstructionsNew extends BaseRoute {
    queryParams = { legalIndividualId: { refreshModel: true } };

    @service
    declare store: Store;

    @service
    declare analytics: Analytics;

    async model(params: any = undefined) {
        const legalIndividual = await this.store.findRecord('legal-individual', params.legalIndividualId);
        return this.store.createRecord('legalIndividualWireInstruction', {
            legalIndividualId: params.legalIndividualId,
            accountName: legalIndividual.accountName,
            street: legalIndividual.street,
            street2: legalIndividual.street2,
            city: legalIndividual.city,
            state: legalIndividual.state,
            zipcode: legalIndividual.zipcode,
            authUserId: legalIndividual.parentUserId
        });
    }

    @action
    didTransition(): void {
        const wireInstruction = this.controller.model as ModelFrom<LegalIndividualWireInstructionsNew>;

        this.analytics.trackPage('Viewed Legal Individual Wire Instructions / New', {
            legalIndividualId: wireInstruction.legalIndividualId
        });
    }
}
