import AdminProfile from 'admin/models/admin-profile';
import Service from '@ember/service';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AuthService extends Service {
    @service
    declare store: Store;

    @tracked
        adminProfile?: AdminProfile;

    get user(): AdminProfile | undefined {
        return this.adminProfile;
    }

    async ensureAuthorized(): Promise<void> {
        // ApplicationAdapter will redirect if server returns a 401 on findAll call
        this.adminProfile = this.adminProfile || (await this.store.findAll('adminProfile')).toArray()[0];
    }
}

declare module '@ember/service' {
    interface Registry {
        auth: AuthService;
    }
}
