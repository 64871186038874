
import Component from '@glimmer/component';
import Document from 'admin/models/document';
import Store from '@ember-data/store';
import { EmporaDropdownOptions } from 'empora-dropdown-options';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

interface EmporaDocumentDropdownArgs {
    name: string;
    label: string;
    documents: Document[];
    onChange: (value: any) => void;
    selected?: any;
    hasEmptyOption?: boolean;
}

export default class EmporaDocumentDropdown extends Component<EmporaDocumentDropdownArgs> {
    @service
    declare store: Store;

    get options(): EmporaDropdownOptions {
        return this.args.documents.map((d) => {
            return { name: d.displayName, value: d.id };
        });
    }

    @action
    onChange(value: any) {
        if (this.args.onChange) {
            this.args.onChange(value);
        }
    }
}