import Component from '@glimmer/component';
import { EmporaDropdownOptions } from 'empora-dropdown-options';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { tracked } from '@glimmer/tracking';

interface EmporaDropdownMenuArgs {
    disabled?: boolean;
    hideLabel?: boolean;
    label: string;
    open?: boolean;
    options: EmporaDropdownOptions;
    onChange: (value: any) => void;
    minWidth?: string;
}

export default class EmporaDropdownMenu extends Component<EmporaDropdownMenuArgs> {
    @tracked
        showDropdown;

    constructor(owner: unknown, args: EmporaDropdownMenuArgs) {
        super(owner, args);

        this.showDropdown = !!this.args.open;
    }

    get uniqueId() {
        return `${this.args.label}-${guidFor(this)}`;
    }

    get minWidth(): string {
        return this.args.minWidth ? this.args.minWidth : '8';
    }

    @action
    toggleDropdown() {
        if (!this.args.disabled) {
            this.showDropdown = !this.showDropdown;
        }
    }

    @action
    onChange(value: any) {
        this.toggleDropdown();
        this.args.onChange(value);
    }
}
