import Component from '@glimmer/component';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { tracked } from '@glimmer/tracking';

interface EmporaTextareaArgs {
    autocapitalize?: boolean;
    autocorrect?: boolean;
    disabled?: boolean;
    hideLabel?: boolean;
    label?: string;
    onInput: (value: any) => void;
    placeholder?: string;
    rows?: number;
    textAreaTestTarget?: string;
    tooltip?: string;
    value?: string;
}

export default class EmporaTextarea extends Component<EmporaTextareaArgs> {
    get uniqueId() {
        return `${guidFor(this)}`;
    }

    get rows() {
        return this.args.rows || 3;
    }

    get textAreaTestTarget() {
        return this.args.textAreaTestTarget || 'empora-textarea__input';
    }

    @tracked
        firstBlurOccurred = false;

    @action
    setFirstBlurOccurred(){
        this.firstBlurOccurred = true;
    }
}