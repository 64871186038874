import Analytics from 'admin/services/analytics';
import BaseRoute from 'admin/routes/base-route';
import DealDealRoles from '../../deal-roles';
import IndividualTitleholderWireInstruction from 'admin/models/individual-titleholder-wire-instruction';
import Store from '@ember-data/store';
import { ModelFrom } from 'admin/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class DealDealRolesIndividualTitleholdersWireInstructions extends BaseRoute {
    @service
    declare analytics: Analytics;

    @service
    declare store: Store;

    async model({ wire_instruction_id }: {wire_instruction_id: string}) {
        const dealRole = this.modelFor('deal.deal-roles') as ModelFrom<DealDealRoles>;
        const wireInstruction = await this.store.findRecord('individual-titleholder-wire-instruction', wire_instruction_id);
        const individualTitleholder = await this.store.findRecord('individual-titleholder', wireInstruction.individualTitleholderId);

        return {
            dealRole,
            wireInstruction,
            individualTitleholder
        };
    }

    @action
    didTransition(): void {
        const wireInstruction = this.controller.model as IndividualTitleholderWireInstruction;

        this.analytics.trackPage('Viewed Deal / Deal Roles / Individual Titleholders / Wire Instructions', {
            individualTitleholderId: wireInstruction.individualTitleholderId
        });
    }
}
