import EmberRouter from '@ember/routing/router';
import config from 'admin/config/environment';
import { withHashSupport } from 'ember-url-hash-polyfill';

@withHashSupport
export default class Router extends EmberRouter {
    location = config.locationType;
    rootURL = '/ember_admin/';
}

Router.map(function () {
    if (config.environment !== 'production') {
        this.route('freestyle');
    }

    this.route('commitment', function () {
        this.route('title-commitment', { path: 'title-commitment/:title_commitment_id' }, function() {
            this.route('index', { path: '/' });
            this.route('reviews');
            this.route('review', { path: 'review/:title_commitment_review_id' });
        });
    });

    this.route('deal', { path: 'deals/:deal_id' }, function () {
        this.route('deal-roles', { path: 'deal-roles/:deal_role_id' }, function () {
            this.route('individual-titleholders', function () {
                this.route('edit', { path: 'edit/:individual_titleholder_id' }, function () {
                    this.route('add-wire-instructions');
                });
                this.route('wire-instructions', { path: 'wire-instructions/:wire_instruction_id' });
            });
        });
    });

    this.route('legal-individual-wire-instructions', function () {
        this.route('index', { path: '/:id' });
        this.route('new');
    });

    this.route('catch-unrecognized', { path: '*' });
    this.route('catch-unrecognized', { path: '/' });
});
