import BaseRoute from 'admin/routes/base-route';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';

export default class DealDealRoles extends BaseRoute {
    @service
    declare store: Store;

    async model({ deal_role_id }: {deal_role_id: string}) {
        return await this.store.findRecord('dealRole', deal_role_id);
    }
}