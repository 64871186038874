import Analytics from 'admin/services/analytics';
import BaseRoute from 'admin/routes/base-route';
import Store from '@ember-data/store';
import { ModelFrom } from 'admin/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class LegalIndividualWireInstructionsIndex extends BaseRoute {
  @service
    declare store: Store;

  @service
  declare analytics: Analytics;

  async model({ id }: {id: string}) {
      return await this.store.findRecord('legal-individual-wire-instruction', id);
  }

  @action
  didTransition(): void {
      const wireInstruction = this.controller.model as ModelFrom<LegalIndividualWireInstructionsIndex>;

      this.analytics.trackPage('Viewed Legal Individual Wire Instructions / Index', {
          legalIndividualId: wireInstruction.legalIndividualId
      });
  }
}