import Controller from '@ember/controller';
import { default as CommitmentTitleCommitmentReviewRoute } from 'admin/routes/commitment/title-commitment/review';
import { ModelFrom } from 'admin/utils/type-utils';

export default class TileCommitmentReview extends Controller {
    declare model: ModelFrom<CommitmentTitleCommitmentReviewRoute>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
      'commitment/title-commitment/review': TileCommitmentReview;
  }
}