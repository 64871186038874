import Model, { attr } from '@ember-data/model';
import validateConfirmation from 'admin/validators/confirmation';
import validateRoutingNumber from 'admin/validators/routing-number';

export default abstract class WireInstruction extends Model {
    @attr('string')
        bankName?: string;

    @attr('string')
        routingNumber?: string;

    @attr('string')
        routingNumberConfirmation?: string;

    @attr('string')
        accountNumber?: string;

    @attr('string')
        accountNumberConfirmation?: string;

    @attr('string')
        instructions?: string;
}

export const optionalWireInstructionValidations = {
    routingNumber: [
        validateRoutingNumber('Routing number', { allowBlank: true })
    ],
    routingNumberConfirmation: [
        validateConfirmation({
            on: 'routingNumber',
            message: 'Routing numbers don’t match'
        })
    ],
    accountNumberConfirmation: [
        validateConfirmation({
            on: 'accountNumber',
            message: 'Account numbers don’t match'
        })
    ]
};

export const requiredWireInstructionValidations = {
    routingNumber: [
        validateRoutingNumber('Routing number', { allowBlank: true })
    ],
    routingNumberConfirmation: [
        validateConfirmation({
            on: 'routingNumber',
            message: 'Routing numbers don’t match'
        })
    ],
    accountNumberConfirmation: [
        validateConfirmation({
            on: 'accountNumber',
            message: 'Account numbers don’t match'
        })
    ]
};

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        wireInstruction: WireInstruction
    }
}
