import Address from './address';
import DealRole, { DealRoleRole } from './deal-role';
import Model, { AsyncHasMany, attr, hasMany } from '@ember-data/model';
import Property from './property';
import { DateTime } from 'luxon';

export enum DealType {
    PASA = 'PASA',
    REFINANCE = 'Refinance',
    WHOLESALE = 'Wholesale'
}

export enum DealStatusNames {
    RECEIVED = 'RECEIVED',
    TITLE_SEARCH_STARTED = 'TITLE_SEARCH_STARTED',
    DEAL_INTAKE_COMPLETED = 'DEAL_INTAKE_COMPLETED',
    TITLE_COMMITMENT_COMPLETED = 'TITLE_COMMITMENT_COMPLETED',
    CLOSING_CONFIRMED = 'CLOSING_CONFIRMED',
    ALL_PARTIES_CLOSED = 'ALL_PARTIES_CLOSED',
    FUNDING_COMPLETED = 'FUNDING_COMPLETED',
    DEAL_RECORDED = 'DEAL_RECORDED',
    CANCELED = 'CANCELED',
    COMPLETED = 'COMPLETED',
    ON_HOLD = 'ON_HOLD',
    OPEN = 'OPEN'
}

export const dealStatusDisplayText = {
    [DealStatusNames.RECEIVED]: 'Received',
    [DealStatusNames.TITLE_SEARCH_STARTED]: 'Title Started',
    [DealStatusNames.DEAL_INTAKE_COMPLETED]: 'Intake Complete',
    [DealStatusNames.TITLE_COMMITMENT_COMPLETED]: 'Commitment Received',
    [DealStatusNames.CLOSING_CONFIRMED]: 'Closing Confirmed',
    [DealStatusNames.ALL_PARTIES_CLOSED]: 'All Parties Closed',
    [DealStatusNames.FUNDING_COMPLETED]: 'Funded',
    [DealStatusNames.DEAL_RECORDED]: 'Recorded',
    [DealStatusNames.CANCELED]: 'Canceled',
    [DealStatusNames.COMPLETED]: 'Completed',
    [DealStatusNames.ON_HOLD]: 'On Hold',
    [DealStatusNames.OPEN]: 'Open'
};

export default class Deal extends Model {
    @attr('boolean')
        autoStatusUpdateEnabled?: boolean;

    @attr('date')
        createdAt?: Date;

    @attr('string')
        dealType?: DealType;

    @attr('string')
        displayDealSubtype?: string;

    @attr('string')
        fileNumber?: string;

    @attr('boolean')
        hasMultipleProperties?: boolean;

    @attr('boolean')
        hasPowerUser?: boolean;

    @attr('boolean')
        multipleDealsForAddress?: boolean;

    @attr('string')
        name?: string;

    @attr('string')
        pinnedUberNote?: string;

    @attr('string')
        status?: DealStatusNames;

    @attr('date')
        ultimateClosingDate?: Date;

    @attr('date')
        lenderRequestedClosingDate?: Date;

    @attr('date')
        disbursementDate?: Date;

    @attr('string')
        mostRecentStatus?: string;

    @attr('string')
        cancelationReason?: string;

    @attr('string')
        otherCancelationReason?: string;

    @attr('number')
        actionItemVersion?: number;

    @hasMany('deal-role')
        dealRoles?: DealRole[];

    @hasMany('property')
        properties?: AsyncHasMany<Property>;

    get addressCityStateZip(): string | undefined {
        if (this.hasMultipleProperties) {
            return undefined;
        }

        return this._onlyPropertyAddress?.get('cityStateZip');
    }

    get addressOneLine(): string | undefined {
        if (this.hasMultipleProperties) {
            return this.displayName;
        }

        return this._onlyPropertyAddress?.get('oneLine');
    }

    get addressStreet(): string | undefined {
        if (this.hasMultipleProperties) {
            return undefined;
        }

        return this._onlyPropertyAddress?.get('street');
    }

    get addressStreet2(): string | undefined {
        if (this.hasMultipleProperties) {
            return undefined;
        }

        return this._onlyPropertyAddress?.get('street2') ?? undefined;
    }

    get addressCounty(): string | undefined {
        if (this.hasMultipleProperties) {
            return undefined;
        }

        return this._onlyPropertyAddress?.get('county');
    }

    get assignorDealRole(): DealRole | undefined {
        return this.dealRoles!.find((d) => d.isAssignor);
    }

    get borrowerDealRole(): DealRole | undefined {
        return this.dealRoles!.find((d) => d.isBorrower);
    }

    get buyerDealRole(): DealRole | undefined {
        return this.dealRoles!.find((d) => d.isBuyer);
    }

    get roleTypesIncludedOnDeal(): (DealRoleRole | undefined)[] {
        const sortedRoleTypes = this.dealRoles!.map((dealRole) => dealRole.role).sort();
        return sortedRoleTypes;
    }

    get displayName(): string | undefined {
        if (this.hasMultipleProperties) {
            return this.name;
        }

        return this.addressStreet;
    }

    get displayStatus(): string {
        if (this.status) {
            return dealStatusDisplayText[this.status as DealStatusNames];
        }

        return '';
    }

    get isPasa(): boolean {
        return this.dealType === DealType.PASA;
    }

    get isRefinance(): boolean {
        return this.dealType === DealType.REFINANCE;
    }

    get isWholesale(): boolean {
        return this.dealType === DealType.WHOLESALE;
    }

    get propertyCountDisplay() {
        const propertyCount = this.properties?.length;
        if (propertyCount === 1) {
            return '1 property';
        }

        return `${propertyCount} properties`;
    }

    get sellerDealRole(): DealRole | undefined {
        return this.dealRoles!.find((d) => d.isSeller);
    }

    closesInNextWeek(): boolean {
        if (!this.ultimateClosingDate) {
            return false;
        }

        const closingDate = DateTime.fromJSDate(this.ultimateClosingDate);
        const oneWeekFromNow = DateTime.now().plus({ weeks: 1 });

        return closingDate < oneWeekFromNow;
    }

    get property(): Property | undefined {
        return this.properties?.toArray()?.[0];
    }

    get isOnHold(): boolean {
        return this.status?.toLowerCase() === dealStatusDisplayText.ON_HOLD.toLowerCase();
    }

    get _onlyPropertyAddress(): Address | undefined {
        return this.property?.address;
    }
}

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        deal: Deal;
    }
}
