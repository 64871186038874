import Model, { attr } from '@ember-data/model';
import fullName from 'admin/utils/full-name';

export default class UserProfile extends Model {
    @attr('string')
        email?: string;

    @attr('string')
        firstName?: string;

    @attr('string')
        middleName?: string;

    @attr('string')
        lastName?: string;

    @attr('string')
        type?: string;

    @attr('string')
        oneLineAddress?: string;

    @attr('string')
        phoneNumber?: string;

    @attr('string')
        emailPreference?: string;

    @attr('string')
        messageNotificationEmailPreference?: string;

    @attr('string')
        phoneCallPreference?: string;

    @attr('string')
        textMessagePreference?: string;

    @attr('string')
        portalMessagePreference?: string;

    get fullName(): string | undefined {
        return fullName(this.firstName, '', this.lastName);
    }
}
