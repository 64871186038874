import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export interface EmporaCheckboxArgs {
    onChange?: (value: any) => void;
    checked?: boolean;
    value?: any;
    name?: string;
}

export default class EmporaCheckbox extends Component<EmporaCheckboxArgs> {
    @tracked
        input?: HTMLInputElement;

    get checked(): boolean {
        return this.input ? this.input.checked : false;
    }

    @action
    registerInput(element: HTMLInputElement) {
        this.input = element;
    }

    @action
    onChange() {
        this.args.onChange?.(this.args.value !== undefined ? this.args.value : this.checked);

        if (typeof this.args.checked === 'boolean') {
            // The parent will control the checked state instead
            this.input!.checked = !this.input!.checked;
        }
    }
}
