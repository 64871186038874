import Analytics from 'admin/services/analytics';
import BaseRoute from 'admin/routes/base-route';
import DealDealRolesIndividualTitleholdersEdit from '../edit';
import Store from '@ember-data/store';
import { ModelFrom } from 'admin/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class DealDealRolesIndividualTitleholdersEditAddWireInstructions extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare analytics: Analytics;

    model() {
        const { individualTitleholder, dealRole } = this.modelFor(
            'deal.deal-roles.individual-titleholders.edit'
        ) as ModelFrom<DealDealRolesIndividualTitleholdersEdit>;

        const wireInstruction = this.store.createRecord('individualTitleholderWireInstruction', {
            individualTitleholderId: individualTitleholder.id,
            accountName: individualTitleholder.accountName,
            street: individualTitleholder.street,
            street2: individualTitleholder.street2,
            city: individualTitleholder.city,
            state: individualTitleholder.state,
            zipcode: individualTitleholder.zipcode,
            dealRoleId: dealRole.id
        });

        return {
            dealRole,
            wireInstruction
        };
    }

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal / Deal Roles / Individual Titleholders / Add Wire Instructions');
    }
}