import Address from './address';
import Model, { attr, belongsTo } from '@ember-data/model';

export default class Property extends Model {
    @attr('string')
        parcelNumbers?: string;

    @attr('boolean', { allowNull: true })
        isRental?: boolean;

    @belongsTo('address')
        address?: Address;

    get combinedStreet(): string {
        return this.address!.get('combinedStreet');
    }

    get rentalProperty(): string {
        if (this.isRental === true) {
            return 'Yes';
        } else if (this.isRental === false) {
            return 'No';
        } else {
            return 'Unknown';
        }
    }
}

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        property: Property;
    }
}
