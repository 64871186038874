import Model, { attr } from '@ember-data/model';
import { DealSide } from './deal-side';

export default class Titleholder extends Model {
    @attr('string')
        name?: string;

    @attr('string')
        dealSide?: DealSide;

    get isCurrent(): boolean {
        return this.dealSide?.toLowerCase() === DealSide.CURRENT;
    }

    get isFuture(): boolean {
        return this.dealSide?.toLowerCase() === DealSide.FUTURE;
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        titleholder: Titleholder;
    }
}
