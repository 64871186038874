import Controller from '@ember/controller';
import { ModelFrom } from 'admin/utils/type-utils';
import { default as TitleCommitmentReviewIndexRoute } from 'admin/routes/commitment/title-commitment/reviews';
import { tracked } from '@glimmer/tracking';


export default class TitleCommitmentReviewIndex extends Controller {
    queryParams = ['role'];

    @tracked
        role!: string;

    declare model: ModelFrom<TitleCommitmentReviewIndexRoute>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
      'commitment/title-commitment/reviews': TitleCommitmentReviewIndex;
  }
}
