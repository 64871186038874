import Component from '@glimmer/component';
import FeatureFlag from 'admin/services/feature-flag';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

interface DealActivityHeaderArgs {
    activityName?: string;
    dealId?: string;
    dealName?: string;
}

export default class DealActivityHeader extends Component<DealActivityHeaderArgs> {
    @service
    declare router: RouterService;

    @service
    declare featureFlag: FeatureFlag;

    @action
    onClick() {
        window.location.href = `/admin/deals/${this.args.dealId!}`;
    }
}