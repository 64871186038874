import Component from '@glimmer/component';
import { BufferedChangeset } from 'ember-changeset/types';
import { action } from '@ember/object';
import { executeDraftChangeset } from 'admin/utils/draft-changeset';
import { stateOptions } from 'admin/models/state';
import { tracked } from '@glimmer/tracking';

interface LockableWireInstructionArgs {
    backText: string;
    changeset: BufferedChangeset;
    onSave: () => void;
    onCancel: () => void;
    warningMessage: string;
}

export default class LockableWireInstruction extends Component<LockableWireInstructionArgs> {
    stateOptions = stateOptions;

    constructor(owner: unknown, args: LockableWireInstructionArgs) {
        super(owner, args);
        this.setLockStatus();
    }

    @tracked
        isLocked?: boolean;

    @tracked
        showErrorBanner?: boolean = false;

    @action
    setLockStatus() {
        this.isLocked = !this.args.changeset.isNew;
    }

    @action
    onUnlock() {
        this.args.changeset.validate();
        this.isLocked = false;
    }

    @action
    onChange() {
        this.args.changeset && executeDraftChangeset(this.args.changeset);
    }

    @action
    async onSave() {
        this.args.changeset?.set('showErrorOnPristine', true);

        if (this.args.changeset?.isInvalid) {
            this.showErrorBanner = true;
            return;
        }

        await this.args.changeset.save();
        this.args.onSave();
    }

    @action
    onCancel() {
        this.args.changeset.rollback();
        this.args.onCancel();
    }

    @action
    onRoutingNumberChanged(value: string) {
        if (this.isLocked) return;
        this.args.changeset.set('routingNumber', value);
        this.args.changeset.validate('routingNumberConfirmation');
    }

    @action
    onRoutingNumberConfirmationChanged(value: string) {
        if (this.isLocked) return;
        this.args.changeset.set('routingNumberConfirmation', value);
        this.args.changeset.validate('routingNumber');
    }

    @action
    onAccountNumberChanged(value: string) {
        if (this.isLocked) return;
        this.args.changeset.set('accountNumber', value);
        this.args.changeset.validate('accountNumberConfirmation');
    }

    @action
    onAccountNumberConfirmationChanged(value: string) {
        if (this.isLocked) return;
        this.args.changeset.set('accountNumberConfirmation', value);
        this.args.changeset.validate('accountNumber');
    }

    get disabledSave() {
        return false;
        return this.args.changeset.isInvalid;
    }
}
