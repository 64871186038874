import Component from '@glimmer/component';
import { EmporaDropdownOptions } from 'empora-dropdown-options';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { tracked } from '@glimmer/tracking';

interface EmporaDropdownArgs {
    name: string;
    label: string;
    options: EmporaDropdownOptions;
    onChange: (value: any) => void;
    selected?: any;
    hasEmptyOption?: boolean;
    ignoreBlurEvent?: boolean;
}

export default class EmporaDropdown extends Component<EmporaDropdownArgs> {
    get uniqueId() {
        return `${this.args.name}-${guidFor(this)}`;
    }

    @tracked
        firstBlurOccurred = false;

    @action
    onBlur(event: any) {
        if (!this.args.ignoreBlurEvent) {
            this.onChange(event);
        }
    }

    @action
    onChange(event: any) {
        if (this.args.onChange) {
            this.args.onChange(event.target.value);
        }
    }

    @action
    setFirstBlurOccurred(){
        this.firstBlurOccurred = true;
    }
}
