import BaseRoute from 'admin/routes/base-route';
import Store from '@ember-data/store';
import { DocumentType } from 'admin/models/document';
import { inject as service } from '@ember/service';

export default class TitleCommitment extends BaseRoute {
    @service
    declare store: Store;

    async model({ title_commitment_id }: { title_commitment_id: string }) {
        const titleCommitment = await this.store.findRecord('commitment-title-commitment', title_commitment_id);

        const documents = await this.store.query('deal-document', {
            deal_id: titleCommitment?.dealId,
            type: DocumentType.commitment
        });

        const dealRoles = await this.store.query('deal-role', {
            deal_id: titleCommitment?.dealId
        });

        return {
            titleCommitment,
            dealRoles: dealRoles.toArray(),
            documents: documents.toArray()
        };
    }
}
