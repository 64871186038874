import AuthService from 'admin/services/auth';
import RouterService from '@ember/routing/router-service';
import Service from '@ember/service';
import { Metrics as EmberMetrics } from 'ember-metrics';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';

export enum AnalyticsProviders {
    MIXPANEL = 'Mixpanel'
}

export default class Analytics extends Service {
    @service
    declare auth: AuthService;

    @service
    declare router: RouterService;

    @service
    declare metrics: EmberMetrics;

    trackPage(event: string, props: Record<string, unknown> = {}): void {
        next(() => {
            this.metrics.trackPage(AnalyticsProviders.MIXPANEL, {
                event: `Ember Admin: ${event}`,
                unique_id: this.auth.user?.email,
                ...props
            });
        });
    }

    trackEvent(event: string, props: Record<string, unknown> = {}): void {
        next(() => {
            this.metrics.trackEvent(AnalyticsProviders.MIXPANEL, {
                event: `Ember Admin: ${event}`,
                unique_id: this.auth.user?.email,
                ...props
            });
        });
    }
}

declare module '@ember/service' {
    interface Registry {
        analytics: Analytics;
    }
}
