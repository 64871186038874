/* eslint-disable ember/use-ember-data-rfc-395-imports */
import ApplicationAdapter from './application';
import ModelRegistry from 'ember-data/types/registries/model';

export default class AdminProfileAdapter extends ApplicationAdapter {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pathForType<K extends keyof ModelRegistry>(_modelName: K): string {
        return 'profile/admin_profiles';
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
    export default interface AdapterRegistry {
        'admin-profile': AdminProfileAdapter;
    }
}
