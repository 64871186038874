import CommitmentTitleCommitmentReview, { SharingMethodKind } from 'admin/models/commitment-title-commitment-review';
import Component from '@glimmer/component';
import DealDocumentModel from 'admin/models/deal-document';
import DealRole from 'admin/models/deal-role';
import FeatureFlag from 'admin/services/feature-flag';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import window from 'ember-window-mock';
import { BufferedChangeset } from 'validated-changeset';
import { DocumentType } from 'admin/models/document';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface CommitmentTitleCommitmentArgs {
    changeset: BufferedChangeset;
    dealRoles: DealRole[];
    documents: DealDocumentModel[];
}

export default class CommitmentTitleCommitment extends Component<CommitmentTitleCommitmentArgs> {
    @tracked
        reviewsByRole: any = [];

    @service
    declare router: RouterService;

    @service
    declare store: Store;

    @service
    declare featureFlag: FeatureFlag;

    @tracked
        disableRequests = !this.args.changeset.document;


    sharingMethodKind = SharingMethodKind;

    documents: { name: string, value: string }[] = [];

    @action
    generateReview(role: string) {
        this.router.transitionTo('commitment.title-commitment.reviews', { queryParams: { role: role } });
    }

    @action
    updateReview(review: CommitmentTitleCommitmentReview) {
        this.router.transitionTo('commitment.title-commitment.review', review.id);
    }

    constructor(owner: unknown, args: CommitmentTitleCommitmentArgs) {
        super(owner, args);
        this.reviewsByRole = args.changeset.get('dealRoles').map((role: string) => {
            const reviews = args.changeset.get('titleCommitmentReviews').filter((review: CommitmentTitleCommitmentReview): boolean => {
                return review.dealRole === role;
            });

            const dealRole = args.dealRoles.find((dealRole) => {
                return dealRole.capitalizedRole === role;
            });

            return {
                role: role,
                reviews: reviews,
                dealRole: dealRole
            };
        });

        this.documents = args.documents.map((document) => {
            return {
                name: document.name,
                value: document.id
            };
        });
    }

    @action
    async onDocumentUpload(doc: DealDocumentModel) {
        const currentDocument = await this.args.changeset.get('document');

        if (currentDocument?.isNew) {
            await currentDocument.content.destroyRecord();
        }

        doc.type = DocumentType.commitment;

        const deal = await this.store.findRecord('deal', this.args.changeset.get('dealId'));
        doc.deal = deal;

        this.disableRequests = true;
        this.args.changeset.set('document', doc);
        this.args.changeset.validate();
    }

    @action
    async onDocumentSelect(document_id: string) {
        if (!document_id) {
            return;
        }
        const currentDocument = await this.args.changeset.get('document');
        const deal = await this.store.findRecord('deal', this.args.changeset.get('dealId'));

        if (currentDocument?.isNew) {
            await currentDocument.content.destroyRecord();
        }

        const document = await this.store.findRecord(
            'deal-document',
            document_id,
            { adapterOptions: { query: { deal_id: deal.id } } }
        );

        this.disableRequests = true;
        this.args.changeset.set('document', document);
        this.args.changeset.validate();
    }

    @action
    onCancel() {
        const dealId = this.args.changeset.get('dealId');
        window.location.href = `/admin/deals/${dealId}`;
    }

    @action
    async save() {
        const document = await this.args.changeset.get('document');

        if (document.isNew) {
            await document.save();
        }

        await this.args.changeset.save();
        this.disableRequests = false;
    }

    @action
    saveDealRole(dealRole: DealRole, value: string) {
        dealRole.ultimateClosingDateVisible = value === 'true';
        dealRole.save();
    }

    get closeDateVisibleOptions() {
        return [
            { name: 'Yes', value: true },
            { name: 'No', value: false }
        ];
    }

    get disabledSave(): boolean {
        return this.args.changeset.isPristine;
    }

    get disabledGenerateRequests(): boolean {
        return !this.args.changeset.document || this.disableRequests;
    }
}
