import CommitmentTitleCommitmentReview from './commitment-title-commitment-review';
import DealDocumentModel from './deal-document';
import Model, { AsyncBelongsTo, AsyncHasMany, attr, belongsTo, hasMany } from '@ember-data/model';

export default class CommitmentTitleCommitment extends Model {
    @attr('string')
        activityName?: string;

    @attr('string')
        dealId?: string;

    @attr('string')
        dealName?: string;

    @attr()
        dealRoles?: Array<string>;

    @belongsTo('document')
        document?: AsyncBelongsTo<DealDocumentModel> | DealDocumentModel;

    @hasMany('commitment-title-commitment-review')
        titleCommitmentReviews?: AsyncHasMany<CommitmentTitleCommitmentReview>;
}

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
      'commitment-title-commitment': CommitmentTitleCommitment;
    }
}
