import BaseFreestyleController from 'ember-freestyle/controllers/freestyle';
import DocumentModel from 'admin/models/document';
import Service, { inject as service } from '@ember/service';
import config from 'admin/config/environment';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

class Person {
    @tracked
        firstName = 'Asuka';

    @tracked
        lastName = 'Langley';

    @tracked
        email = 'asuka@nerv.gov';

    @tracked
        phone = '(111) 111-1111';

    @tracked
        password = 'n0_p33k1nG';

    @tracked
        placeholder = '';
}

export default class FreestyleController extends BaseFreestyleController {
    @service
        emberFreestyle!: Service;

    get colorPalette() {
        const { colors } = config;
        return Object.keys(colors).reduce(
            (carry: {[key: string]: any}, colorName: string) => {
                const weights = colors[colorName];
                Object.keys(weights).forEach((weight: string) => {
                    carry[`${colorName}-${weight}`] = {
                        name: `${colorName}-${weight}`,
                        base: colors[colorName][weight as unknown as number]
                    };
                });
                return carry;
            },
            {}
        );
    }

    // Button
    @action
    onButtonClicked() {
        alert('Thanks! :D');
    }

    // Checkbox
    @tracked
        checkboxValue = false;

    @action
    onCheckboxChange(value: boolean) {
        this.checkboxValue = value;
    }

    @action
    onConfirmableCheckboxChange(value: boolean) {
        if (window.confirm('Are you sure?')) {
            this.onCheckboxChange(value);
        }
    }

    @tracked
        dueAt?: Date;

    // Document Upload
    @tracked
        document?: DocumentModel;

    @action
    onDocumentUpload(doc: DocumentModel) {
        this.document = doc;
    }

    // Dropdown
    dropdownOptions: Array<{name: string; value: any}> = [
        { value: 'option_1', name: 'Option 1' },
        { value: 'option_2', name: 'Option 2' },
        { value: 'option_3', name: 'Option 3' },
        { value: 'option_4', name: 'Option 4' },
        { value: 'option_5', name: 'Option 5, which has a very long name that runs underneath the chevron' }
    ];

    @tracked
        dropdownValue: any = '';

    @action
    onDropdownChange(value: any) {
        this.dropdownValue = value;
    }

    // Text
    textPerson = new Person();

    @tracked
        notes?: string;
}
