import Component from '@glimmer/component';
import { BufferedChangeset } from 'ember-changeset/types';
import { action } from '@ember/object';
import { stateOptions } from 'admin/models/state';

interface EmporaAddressArgs {
    disabled?: boolean;
    title: string;
    subtitle: string;
    changeset: BufferedChangeset;
}

export default class EmporaAddress extends Component<EmporaAddressArgs> {
    @action
    validate() {
        if (this.args.changeset.validate) {
            this.args.changeset.validate();
        }
    }

    states = stateOptions;
}