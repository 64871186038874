/*global module require*/
const colors = require('tailwindcss/colors');

const emporaColors = require('../config/colors.js');

module.exports = {
    content: [
        './public/**/*.html',
        './app/**/*.{ts,js,hbs}'
    ],
    theme: {
        extend: {
            zIndex: {
                '-1': '-1'
            },
            flex: {
                2: '2 2 0%'
            },
            minHeight: {
                8: '2rem'
            },
            width: {
                '1/24': '4.1666665%'
            },
            gridTemplateRows: {
                '10': 'repeat(10, minmax(0, 1fr))'
            }
        },
        colors: Object.assign(
            {},
            {
                transparent: 'transparent',
                current: 'currentColor',

                black: colors.black,
                white: colors.white
            },
            emporaColors
        ),
        fontFamily: {
            sans: [
                'system-ui',
                '-apple-system',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                '"Noto Sans"',
                '"Liberation Sans"',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
                '"Noto Color Emoji"'
            ],
            serif: [
                'Georgia',
                '"Times New Roman"',
                'Times',
                'serif'
            ],
            mono: [
                'ui-monospace',
                'SFMono-Regular',
                'Menlo',
                'Monaco',
                'Consolas',
                '"Liberation Mono"',
                '"Courier New"',
                'monospace'
            ]
        }
    },
    plugins: []
};
