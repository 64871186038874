import AuthService from 'admin/services/auth';
import FeatureFlag from 'admin/services/feature-flag';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class BaseRoute extends Route {
    @service
    declare auth: AuthService;

    @service
    declare featureFlag: FeatureFlag;

    async beforeModel() {
        await this.auth.ensureAuthorized();
        await this.featureFlag.loadFeatureFlags();
    }
}
