/* eslint-disable ember/use-ember-data-rfc-395-imports */
import DS from 'ember-data';
import ENV from 'admin/config/environment';
import JSONAPIAdapter from '@ember-data/adapter/json-api';
import ModelRegistry from 'ember-data/types/registries/model';
import RouterService from '@ember/routing/router-service';
import window from 'ember-window-mock';
import { inject as service } from '@ember/service';

export default class ApplicationAdapter extends JSONAPIAdapter {
    namespace = ENV.API_NAMESPACE;
    host = ENV.API_URL;

    @service
        router!: RouterService;

    handleResponse(status: any, headers: any, payload: any, requestData: any) {
        if (status === 401) {
            window.location.replace('/admin/login');
            return false;
        } else if (status === 302 && payload.permission === 'vendor') {
            window.location.replace('/title_search/title_reports');
            return false;
        }

        return super.handleResponse(status,headers,payload,requestData);
    }

    pathForType<K extends keyof ModelRegistry>(modelName: K): string {
        if ((modelName as string).includes('commitment')) {
            const modelPath = super.pathForType(modelName);
            const moduleModelPath = modelPath.replace(/commitment-/, 'commitment/');
            return moduleModelPath.replace(/-/g, '_');
        }
        else {
            return super.pathForType(modelName).replace(/-/g, '_');
        }
    }

    urlForFindRecord<K extends keyof ModelRegistry>(id: string, modelName: K, snapshot: DS.Snapshot): string {
        const url = super.urlForFindRecord(id, modelName, snapshot);

        const query = (snapshot.adapterOptions as any)?.query;
        if (!query) {
            return url;
        }

        return url + '?' + new URLSearchParams(query).toString();
    }
}

declare module 'ember-data/types/registries/adapter' {
    export default interface AdapterRegistry {
        application: ApplicationAdapter;
    }
}
