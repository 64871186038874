import Model, { attr } from '@ember-data/model';

export default class LegalIndividual extends Model {
    @attr('string')
        accountName?: string;

    @attr('string')
        street?: string;

    @attr('string')
        street2?: string;

    @attr('string')
        city?: string;

    @attr('string')
        state?: string;

    @attr('string')
        zipcode?: string;

    @attr('string')
        parentUserId?: string;
}

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        legalIndividual: LegalIndividual
    }
}