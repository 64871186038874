import Component from '@glimmer/component';
import DocumentModel from 'admin/models/document';
import Store from '@ember-data/store';
import { UploadFile } from 'ember-file-upload';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface EmporaDocumentUploadArgs {
    validFiletypes?: string[];
    isUploading?: boolean;
    onUpload?: (
        doc: DocumentModel | undefined,
        DocumentUploadInstance: EmporaDocumentUpload
    ) => void;
    document?: DocumentModel;
    documentModelName?: string;
}

const defaultValidFileTypes = ['pdf', 'pages', 'docx', 'doc', 'jpeg', 'jpg', 'png', 'heic'];

export default class EmporaDocumentUpload extends Component<EmporaDocumentUploadArgs> {
    @service
    declare store: Store;

    @tracked
        file?: File;

    @tracked
        document?: DocumentModel;

    @tracked
        displayName?: string;

    @tracked
        displayFiletype?: string;

    constructor(owner: unknown, args: EmporaDocumentUploadArgs) {
        super(owner, args);
        this.document = args.document;
        this.displayName = args.document?.name;
        this.displayFiletype = args.document?.filetype;
    }

    get validFiletypes() {
        return this.args.validFiletypes || defaultValidFileTypes;
    }

    get fileName(): string {
        if (!this.file) {
            return '';
        }
        return this.file.name.substr(0, this.file.name.lastIndexOf('.'));
    }

    get fileType(): string {
        if (!this.file) {
            return '';
        }
        return this.file.name.split('.').lastObject || '';
    }

    get fileTypeValid(): boolean {
        if (!this.file) {
            return false;
        }

        return this.validFiletypes.includes(this.fileType.toLowerCase());
    }

    get displayFileTypeInvalid(): boolean {
        return !this.fileTypeValid && !!this.file;
    }

    get fileTypeList(): string {
        return this.validFiletypes
            .map((filetype) => {
                return `.${filetype}`;
            })
            .join(', ');
    }

    clearDocument() {
        this.file = undefined;
        this.document = undefined;
    }

    @action
    async onDragAndDrop(uploadFile: UploadFile) {
        this.uploadFile(uploadFile.file as File);
    }

    @action
    async onInputChange(e: Event) {
        const files = (e.target as HTMLInputElement).files;
        if (files === null) return;
        if (files.length === 0) return;

        this.uploadFile(files[0] as File);

        // This is a bit of a hack to allow subsequent uploads of the same file name on a single page
        // the change event doesn't fire if a file with the same filename is uploaded twice even if they are different
        (e.target as HTMLInputElement).value = '';
    }

    uploadFile(file: File) {
        this.file = file;

        this.document = this.store.createRecord(this.args.documentModelName ?? 'document', {
            filetype: `.${this.fileType}`,
            name: this.fileName,
            file: this.file
        });

        if (!this.fileTypeValid) return;

        if (this.args.onUpload) {
            this.args.onUpload(this.document, this);
        }

        this.displayName = this.document?.name;
        this.displayFiletype = this.document?.filetype;
    }
}
