import CommitmentTitleCommitment from './commitment-title-commitment';
import DealDocumentModel from './deal-document';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { EmporaDropdownOptions } from 'empora-dropdown-options';

export enum SharingMethodKind {
    portal = 'portal',
    manual = 'manual'
}

export const sharingMethods: EmporaDropdownOptions = [
    {
        name: 'Via the Portal',
        value: SharingMethodKind.portal
    },
    {
        name: 'Manually (Phone or Email)',
        value: SharingMethodKind.manual
    }
];

export default class CommitmentTitleCommitmentReview extends Model {
    @attr('date')
        dueDate?: Date;

    @attr('date')
        reviewedWithUserAt?: Date;

    @attr('boolean')
        reviewedWithUser?: boolean;

    @attr('string')
        completedByUserEmail?: string;

    @attr('string')
        messageSent?: string;

    @attr('string')
        customerQuestion?: string;

    @attr('string')
        manualResponseNote?: string;

    @attr('string')
        status?: string;

    @attr('string')
        activityId?: string;

    @attr('string')
        dealRole?: string;

    @attr('boolean')
        understoodByUser?: boolean;

    @attr('string')
        sharingMethod?: string;

    @belongsTo('document')
        document?: AsyncBelongsTo<DealDocumentModel> | DealDocumentModel;

    @belongsTo('commitment-title-commitment')
        titleCommitment?: AsyncBelongsTo<CommitmentTitleCommitment>;

    get isActionItemDeleted(): boolean {
        return this.sharingMethod === SharingMethodKind.portal && !this.activityId;
    }
}

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
      'commitment-title-commitment-review': CommitmentTitleCommitmentReview;
    }
}
