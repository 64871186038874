import Component from '@glimmer/component';
import FeatureFlag from 'admin/services/feature-flag';
import Store from '@ember-data/store';
import UserProfile from 'admin/models/user-profile';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';

interface UserCommunicationPreferenceArgs {
    userProfile: UserProfile
}

const COMMUNICATION_PREFERENCES = ['always', 'only_when_necessary', undefined];

export default class UserCommunicationPreferences extends Component<UserCommunicationPreferenceArgs> {
    save = task(this, async (preference: string) => {
        const userProfile = await this.store.findRecord('user-profile', this.args.userProfile.get('id'));
        const preferenceName = `${preference}Preference` as keyof UserProfile;
        const currentPreference = userProfile.get(preferenceName);
        const index = COMMUNICATION_PREFERENCES.indexOf(currentPreference as string | undefined);
        const newPreference = COMMUNICATION_PREFERENCES[(index + 1) % COMMUNICATION_PREFERENCES.length];

        userProfile.set(preferenceName, newPreference);
        await userProfile.save();
    });

    @service
    declare featureFlag: FeatureFlag;

    @service
    declare store: Store;

    @action
    async onPreferenceClick(preference: string): Promise<void> {
        await this.save.perform(preference);
    }

    get emailSvg(): string {
        return 'email_' + (this.args.userProfile.get('emailPreference') || 'unknown');
    }

    get messageNotificationEmailSvg(): string {
        return 'message_notification_email_' + (this.args.userProfile.get('messageNotificationEmailPreference') || 'unknown');
    }

    get portalMessageSvg(): string {
        return 'portal_message_' + (this.args.userProfile.get('portalMessagePreference') || 'unknown');
    }

    get phoneCallSvg(): string {
        return 'phone_call_' + (this.args.userProfile.get('phoneCallPreference') || 'unknown');
    }

    get textMessageSvg(): string {
        return 'text_message_' + (this.args.userProfile.get('textMessagePreference') || 'unknown');
    }

    get newMessageNotificationEmailPreferenceEnabled() {
        return this.featureFlag.enabled('new_message_notification_email_preference');
    }
}