import Actor from './actor';
import Deal from './deal';
import Model, { AsyncBelongsTo, attr, belongsTo, hasMany } from '@ember-data/model';
import UserProfile from './user-profile';

export enum DealRoleRole {
    BUYER = 'BUYER',
    SELLER = 'SELLER',
    ASSIGNOR = 'ASSIGNOR',
    BORROWER = 'BORROWER'
}

export default class DealRole extends Model {
    @attr('string')
        role?: DealRoleRole;

    @attr('number')
        sortKey?: number;

    @attr('boolean')
        ultimateClosingDateVisible?: boolean;

    @hasMany('user-profile')
        userProfiles?: UserProfile[];

    @hasMany('actor')
        actors?: Actor[];

    @belongsTo('deal')
        deal?: AsyncBelongsTo<Deal> | Deal;

    get capitalizedRole(): string | undefined {
        return this.lowercaseRole?.replace(/^\w/, (c) => c.toUpperCase());
    }

    get lowercaseRole(): string | undefined {
        return this.role?.toLowerCase();
    }

    get isBuyer(): boolean {
        return this.role === DealRoleRole.BUYER;
    }

    get isSeller(): boolean {
        return this.role === DealRoleRole.SELLER;
    }

    get isAssignor(): boolean {
        return this.role === DealRoleRole.ASSIGNOR;
    }

    get isBorrower(): boolean {
        return this.role === DealRoleRole.BORROWER;
    }
}
