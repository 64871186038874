import WireInstruction, { requiredWireInstructionValidations } from './wire-instruction';
import { attr } from '@ember-data/model';
import { requiredAddressValidations } from './address';

export default class IndividualTitleholderWireInstruction extends WireInstruction {
    @attr('string')
        accountName?: string;

    @attr('string')
        street?: string;

    @attr('string')
        street2?: string;

    @attr('string')
        city?: string;

    @attr('string')
        state?: string;

    @attr('string')
        zipcode?: string;

    @attr('string')
        individualTitleholderId?: string;
}

export const individualTitleholderWireInstructionValidations = Object.assign(
    {}, requiredWireInstructionValidations, requiredAddressValidations
);

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        individualTitleholderWireInstruction: IndividualTitleholderWireInstruction
    }
}