import DealRole from './deal-role';
import Titleholder from './titleholder';
import { attr, belongsTo } from '@ember-data/model';

export default class IndividualTitleholder extends Titleholder {
    @attr('string')
        accountName?: string;

    @attr('string')
        street?: string;

    @attr('string')
        street2?: string;

    @attr('string')
        city?: string;

    @attr('string')
        state?: string;

    @attr('string')
        zipcode?: string;

    @belongsTo('deal-role')
        dealRole?: DealRole;

    @attr('string')
        email?: string;

    @attr('string')
        firstName?: string;

    @attr('string')
        middleName?: string;

    @attr('string')
        lastName?: string;

    @attr('string')
        suffix?: string;

    @attr('boolean')
        isMarried?: boolean;

    @attr('string')
        phoneNumber?: string;

    @attr('string')
        spouseEmail?: string;

    @attr('string')
        spouseSuffix?: string;

    @attr('string')
        spouseFirstName?: string;

    @attr('string')
        spouseMiddleName?: string;

    @attr('string')
        spouseLastName?: string;
}
