import Component from '@glimmer/component';
import ENV from 'admin/config/environment';
import window from 'ember-window-mock';
import { EmporaDropdownOptions } from 'empora-dropdown-options';
import { action } from '@ember/object';
import { railsUrl } from 'admin/helpers/rails-url';
import { tracked } from '@glimmer/tracking';

enum LeftDropdownActions {
    DEAL_CREATOR = 'dealCreator',
    FEATURE_FLAGS = 'featureFlags',
    IMPERSONATIONS = 'impersonations',
    ASSIGNMENT_SETTINGS = 'assignmentSettings',
    ADMIN_PROFILES = 'adminProfiles',
    TITLE_GLOSSARY = 'titleGlossary',
    ACTION_ITEM_TEMPLATES = 'actionItemTemplates'
}

enum AccountingDropdownActions {
    AGGREGATE_PAYMENTS = 'aggregatePayments',
    BULK_DEPOSITS = 'bulkDeposits',
    DAILY_CLEARING = 'dailyClearing',
    MONTHLY_RECONCILIATION = 'monthlyReconciliation',
    SHIPMENTS = 'shipments',
    REPORTS = 'accountingReports'
}

enum RightDropdownActions {
    SIDEKIQ = 'sidekiq',
    EVENT_STORE = 'eventStore',
    COMPANY_HOLIDAYS = 'companyHolidays'
}

export default class TopBar extends Component {
    @tracked
        showLeftDropdown = false;

    @tracked
        showAccountingDropdown = false;

    @tracked
        showRightDropdown = false;

    @action
    home() {
        window.location.href = railsUrl(['home']);
    }

    @action
    actionItemTemplates() {
        window.location.href = railsUrl(['action_item_templates']);
    }

    @action
    deals() {
        window.location.href = railsUrl(['deals']);
    }

    @action
    users() {
        window.location.href = railsUrl(['profile/users']);
    }

    @action
    teams() {
        window.location.href = railsUrl(['profile/teams']);
    }

    @action
    contacts() {
        window.location.href = railsUrl(['contacts']);
    }

    @action
    dealCreator() {
        window.location.href = railsUrl(['deals/new']);
    }

    @action
    featureFlags() {
        window.location.href = railsUrl(['feature_flags']);
    }

    @action
    impersonations() {
        window.location.href = railsUrl(['impersonations']);
    }

    @action
    assignmentSettings() {
        window.location.href = railsUrl(['assignment/settings']);
    }

    @action
    adminProfiles() {
        window.location.href = railsUrl(['profile/admins']);
    }


    @action
    titleGlossary() {
        window.location.href = railsUrl(['title_glossary_entries']);
    }

    // AGGREGATE_PAYMENTS = 'aggregatePayments',
    @action
    aggregatePayments() {
        window.location.href = railsUrl(['payments/expected_aggregate_payments']);
    }

    // BULK_DEPOSITS = 'bulkDeposits',
    @action
    bulkDeposits() {
        window.location.href = railsUrl(['payments/bulk_deposits']);
    }

    // DAILY_CLEARING = 'dailyClearing',
    @action
    dailyClearing() {
        window.location.href = railsUrl(['reconciliation/bank_transaction_exceptions']);
    }

    // MONTHLY_RECONCILIATION = 'monthlyReconciliation',
    @action
    monthlyReconciliation() {
        window.location.href = railsUrl(['reconciliation/monthly_reconciliations']);
    }

    // SHIPMENTS = 'shipments',
    @action
    shipments() {
        window.location.href = railsUrl(['shipments']);
    }

    @action
    sidekiq() {
        window.location.href = railsUrl(['sidekiq']);
    }

    @action
    eventStore() {
        window.location.href = railsUrl(['event_store']);
    }

    @action
    companyHolidays() {
        window.location.href = railsUrl(['company_holidays']);
    }

    @action
    accountingReports() {
        window.location.href = railsUrl(['reports/accounting']);
    }

    @action
    toggleLeftDropdown() {
        this.showLeftDropdown = !this.showLeftDropdown;

        if (this.showLeftDropdown) {
            this.showRightDropdown = false;
            this.showAccountingDropdown = false;
        }
    }

    @action
    toggleAccountingDropdown() {
        this.showAccountingDropdown = !this.showAccountingDropdown;

        if (this.showAccountingDropdown) {
            this.showLeftDropdown = false;
            this.showRightDropdown = false;
        }
    }

    @action
    toggleRightDropdown() {
        this.showRightDropdown = !this.showRightDropdown;

        if (this.showRightDropdown) {
            this.showLeftDropdown = false;
            this.showAccountingDropdown = false;
        }
    }

    @action
    onLeftDropdownSelect(action: LeftDropdownActions) {
        this.toggleLeftDropdown();
        this[action]();
    }

    @action
    onAccountingDropdownSelect(action: AccountingDropdownActions) {
        this.toggleAccountingDropdown();
        this[action]();
    }

    @action
    onRightDropdownSelect(action: RightDropdownActions) {
        this.toggleRightDropdown();
        this[action]();
    }

    get leftDropdownOptions(): EmporaDropdownOptions {
        return [
            { name: 'Deal Creator', value: LeftDropdownActions.DEAL_CREATOR },
            { name: 'Feature Flags', value: LeftDropdownActions.FEATURE_FLAGS },
            { name: 'Impersonations', value: LeftDropdownActions.IMPERSONATIONS },
            { name: 'Assignment Settings', value: LeftDropdownActions.ASSIGNMENT_SETTINGS },
            { name: 'Admin Profiles', value: LeftDropdownActions.ADMIN_PROFILES },
            { name: 'Title Glossary', value: LeftDropdownActions.TITLE_GLOSSARY },
            { name: 'Action Item Templates', value: LeftDropdownActions.ACTION_ITEM_TEMPLATES }
        ];
    }

    get accountingDropdownOptions(): EmporaDropdownOptions {
        return [
            { name: 'Aggregate Payments', value: AccountingDropdownActions.AGGREGATE_PAYMENTS },
            { name: 'Bulk Deposits', value: AccountingDropdownActions.BULK_DEPOSITS },
            { name: 'Daily Clearing', value: AccountingDropdownActions.DAILY_CLEARING },
            { name: 'Monthly Reconciliation', value: AccountingDropdownActions.MONTHLY_RECONCILIATION },
            { name: 'Shipments', value: AccountingDropdownActions.SHIPMENTS },
            { name: 'Reports', value: AccountingDropdownActions.REPORTS }
        ];
    }

    get rightDropdownOptions(): EmporaDropdownOptions {
        return [
            { name: 'Sidekiq', value: RightDropdownActions.SIDEKIQ },
            { name: 'Event Store', value: RightDropdownActions.EVENT_STORE },
            { name: 'Company Holidays', value: RightDropdownActions.COMPANY_HOLIDAYS }
        ];
    }

    get backgroundColor() {
        const appName = ENV.HEROKU_APP_NAME;
        if (appName?.includes('production') || appName?.includes('demo')) return 'bg-gray-900';
        if (appName?.includes('staging')) return 'bg-green-600';
        return 'bg-red-700';
    }
}
