import BaseRoute from 'admin/routes/base-route';
import DealDealRoles from 'admin/routes/deal/deal-roles';
import IndividualTitleholder from 'admin/models/individual-titleholder';
import Store from '@ember-data/store';
import { ModelFrom } from 'admin/utils/type-utils';
import { inject as service } from '@ember/service';

export default class DealDealRolesIndividualTitleholdersEdit extends BaseRoute {
    @service
    declare store: Store;

    async model({ individual_titleholder_id }: {individual_titleholder_id: string}) {
        const dealRole = this.modelFor('deal.deal-roles') as ModelFrom<DealDealRoles>;
        const individualTitleholder =
          await this.store.findRecord('individual-titleholder', individual_titleholder_id) as IndividualTitleholder;

        return {
            dealRole,
            individualTitleholder
        };
    }
}