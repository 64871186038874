import Controller from '@ember/controller';
import IndividualTitleholdersEditAddWireInstructionsRoute
    from 'admin/routes/deal/deal-roles/individual-titleholders/edit/add-wire-instructions';
import RouterService from '@ember/routing/router-service';
import window from 'ember-window-mock';
import { ModelFrom } from 'admin/utils/type-utils';
import { action } from '@ember/object';
import { individualTitleholderWireInstructionValidations } from 'admin/models/individual-titleholder-wire-instruction';
import { railsUrl } from 'admin/helpers/rails-url';
import { inject as service } from '@ember/service';

export default class DealDealRolesIndividualTitleholdersEditAddWireInstructions extends Controller {
    validations = individualTitleholderWireInstructionValidations;

    declare model: ModelFrom<IndividualTitleholdersEditAddWireInstructionsRoute>;

    @service
    declare router: RouterService;

    @action
    onSave(): void {
        window.location.href = railsUrl([`deal_role_info/${this.model.dealRole.id}`]);
    }

    @action
    onCancel(): void {
        window.location.href = railsUrl([`deal_role_info/${this.model.dealRole.id}`]);
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'deal/deal-roles/individual-titleholders/edit/add-wire-instructions': DealDealRolesIndividualTitleholdersEditAddWireInstructions;
    }
}
