import { BufferedChangeset } from 'ember-changeset/types';
import { Changeset } from 'ember-changeset';

export default function DraftChangeset(changeset: BufferedChangeset): BufferedChangeset {
    const draftChangeset = Changeset(changeset.data);
    changeset.changes.forEach(({ key, value }) => {
        draftChangeset.set(key, value);
    });

    return draftChangeset;
}

export function executeDraftChangeset(changeset: BufferedChangeset): void {
    DraftChangeset(changeset).execute();
}