import Model, { attr } from '@ember-data/model';
import { oneLineAddress, oneLineAddressParts } from 'admin/models/basic-address';
import { validateFormat, validatePresence } from 'ember-changeset-validations/validators';

export default class Address extends Model {
    @attr('string')
        street!: string;

    @attr('string')
        street2?: string | null;

    @attr('string')
        city!: string;

    @attr('string')
        state!: string;

    @attr('string')
        zipcode!: string;

    @attr('string')
        county!: string;

    get oneLine(): string {
        return oneLineAddress(this);
    }

    get combinedStreet(): string {
        return oneLineAddressParts(this)[0];
    }

    get cityStateZip(): string {
        return oneLineAddressParts(this)[1];
    }
}

export const addressValidations = {
    street: validatePresence({
        presence: true,
        ignoreBlank: true,
        on: ['city', 'state', 'zipcode'],
        message: 'Address can’t be blank'
    }),
    city: validatePresence({
        presence: true,
        ignoreBlank: true,
        on: 'street'
    }),
    state: validatePresence({
        presence: true,
        on: 'city',
        message: 'State must be selected'
    }),
    zipcode: [
        validatePresence({
            presence: true,
            ignoreBlank: true,
            on: 'state',
            message: 'ZIP code can’t be blank'
        }),
        validateFormat({
            allowBlank: true,
            regex: /^[0-9]{5}(-[0-9]{4})?$/,
            message: 'ZIP code is invalid'
        })
    ]
};

export const requiredAddressValidations = {
    street: validatePresence({
        presence: true,
        ignoreBlank: true,
        message: 'Address can’t be blank'
    }),
    city: validatePresence({
        presence: true,
        ignoreBlank: true
    }),
    state: validatePresence({
        presence: true,
        message: 'State must be selected'
    }),
    zipcode: [
        validatePresence({
            presence: true,
            ignoreBlank: true,
            message: 'ZIP code can’t be blank'
        }),
        validateFormat({
            allowBlank: true,
            regex: /^[0-9]{5}(-[0-9]{4})?$/,
            message: 'ZIP code is invalid'
        })
    ]
};

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        address: Address;
    }
}
