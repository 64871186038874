import Analytics from 'admin/services/analytics';
import BaseRoute from 'admin/routes/base-route';
import CommitmentTitleCommitmentReview, { SharingMethodKind } from 'admin/models/commitment-title-commitment-review';
import CommitmentTitleCommitmentRoute from '../title-commitment';
import Store from '@ember-data/store';
import Transition from '@ember/routing/-private/transition';
import { ModelFrom } from 'admin/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class TitleCommitmentReviewIndex extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare analytics: Analytics;

    async model(params: {role: string}) {
        const { titleCommitment } = this.modelFor('commitment.title-commitment') as ModelFrom<CommitmentTitleCommitmentRoute>;

        const actors = await this.store.query('actor', {
            deal_id: titleCommitment?.dealId,
            role: params.role
        });

        return {
            titleCommitmentReview: this.store.createRecord('commitment-title-commitment-review', {
                titleCommitment,
                document: titleCommitment.document,
                sharingMethod: SharingMethodKind.portal,
                dealRole: params.role
            }),
            actors: actors.toArray()
        };
    }

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Title Commitment / Reviews');
    }

    @action
    willTransition(transition: Transition) {
        if (!transition.to.name.includes(this.routeName)) {
            const model = this.modelFor(this.routeName) as any;
            (model.titleCommitmentReview as CommitmentTitleCommitmentReview).rollbackAttributes();
        }
    }
}
