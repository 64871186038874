import Model, { attr } from '@ember-data/model';
import fullName from 'admin/utils/full-name';
export default class AdminProfile extends Model {
    @attr('string')
        email?: string;

    @attr('string')
        firstName?: string;

    @attr('string')
        lastName?: string;

    @attr('string')
        profilePictureUrl?: string;

    get fullName(): string | undefined {
        return fullName(this.firstName, '', this.lastName);
    }
}