import Deal from 'admin/models/deal';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import UserProfile from './user-profile';
import fullName from 'admin/utils/full-name';

export enum ActorRole {
    LENDER = 'lender',
    OTHER_REPRESENTATIVE = 'other_representative',
    POWER_OF_ATTORNEY = 'power_of_attorney',
    REAL_ESTATE_AGENT = 'real_estate_agent',
    TRANSACTION_COORDINATOR = 'transaction_coordinator'
}

export default class Actor extends Model {
    @attr('string')
        familyName?: string;

    @attr('string')
        givenName?: string;

    @attr('string')
        email?: string;

    @attr('boolean')
        inviteSent!: boolean;

    @attr('boolean')
        inviteAccepted!: boolean;

    @attr('string')
        role?: string;

    @attr('string')
        teamId?: string;

    @belongsTo('deal')
        deal!: AsyncBelongsTo<Deal>;

    @attr('string')
        userProfileId?: string;

    @belongsTo('user-profile')
        userProfile!: AsyncBelongsTo<UserProfile>;

    get invitePending(): boolean {
        return this.inviteSent && !this.inviteAccepted;
    }

    get fullName(): string {
        return fullName(this.givenName, undefined, this.familyName);
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'actor': Actor;
  }
}
