/* eslint-disable ember/use-ember-data-rfc-395-imports */
import DS from 'ember-data';
import DealDocumentModel from 'admin/models/deal-document';
import DocumentAdapter from './document';
import DocumentUploadRequest from 'admin/models/document-upload-request';
import ModelRegistry from 'ember-data/types/registries/model';
import Store from '@ember-data/store';

export default class DealDocumentAdapter extends DocumentAdapter {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pathForType<K extends keyof ModelRegistry>(_modelName: K): string {
        return 'documents/deal_documents';
    }

    createDocumentUploadRequest(store: Store, snapshot: DS.Snapshot): DocumentUploadRequest {
        const documentRecord = snapshot.record as DealDocumentModel;

        return store.createRecord('document-upload-request', {
            deal: documentRecord.deal
        });
    }

    urlForFindRecord<K extends keyof ModelRegistry>(id: string, modelName: K, snapshot: DS.Snapshot): string {
        const query = (snapshot.adapterOptions as any)?.query;

        if (!query?.deal_id) {
            throw new Error('Must provide a deal id when finding a deal document');
        }

        return super.urlForFindRecord(id, modelName, snapshot);
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
    export default interface AdapterRegistry {
        dealDocument: DealDocumentAdapter;
    }
}
