import Deal from './deal';
import DocumentModel from './document';
import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

export enum SharedWithRole {
    all = 'All',
    internal = 'Internal',
    buyer = 'Buyer',
    seller = 'Seller',
    assignor = 'Assignor',
    borrower = 'Borrower'

}

export default class DealDocumentModel extends DocumentModel {
    @attr('string')
        presignedDownloadUrl?: string;

    @attr()
        sharedWith?: string | string[];

    @attr('string')
        displayType?: string;

    @attr('string')
        uploadedBy?: string;

    @belongsTo('deal')
        deal?: AsyncBelongsTo<Deal> | Deal;

    @attr()
        folders!: string[];
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        dealDocument: DealDocumentModel;
    }
}
