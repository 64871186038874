import Controller from '@ember/controller';
import LegalIndividualWireInstructionsNewRoute from 'admin/routes/legal-individual-wire-instructions/new';
import RouterService from '@ember/routing/router-service';
import window from 'ember-window-mock';
import { ModelFrom } from 'admin/utils/type-utils';
import { action } from '@ember/object';
import { legalIndividualWireInstructionValidations } from 'admin/models/legal-individual-wire-instruction';
import { railsUrl } from 'admin/helpers/rails-url';
import { inject as service } from '@ember/service';

export default class LegalIndividualWireInstructionsNewIndex extends Controller {
    declare model: ModelFrom<LegalIndividualWireInstructionsNewRoute>;

    queryParams = ['legalIndividualId'];
    validations = legalIndividualWireInstructionValidations;

    legalIndividualId?: string;

    @service
    declare router: RouterService;

    @action
    onCancel() {
        window.location.href = railsUrl([`profile/users/${this.model.authUserId}`]);
    }

    @action
    onSave() {
        window.location.href = railsUrl([`profile/users/${this.model.authUserId}`]);
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'legal-individual-wire-instructions/new/index': LegalIndividualWireInstructionsNewIndex;
    }
}
