import Actor from 'admin/models/actor';
import CommitmentTitleCommitment from 'admin/models/commitment-title-commitment';
import Component from '@glimmer/component';
import RouterService from '@ember/routing/router-service';
import { BufferedChangeset } from 'ember-changeset/types';
import { SharingMethodKind, sharingMethods } from 'admin/models/commitment-title-commitment-review';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

interface CommitmentTitleCommitmentReviewArgs {
    changeset: BufferedChangeset;
    actors: Actor[];
}

export default class CommitmentTitleCommitmentReview extends Component<CommitmentTitleCommitmentReviewArgs> {
    sharingMethod = sharingMethods;
    sharingMethodKind = SharingMethodKind;

    @service
        router!: RouterService;

    @action
    cancel(titleCommitment: CommitmentTitleCommitment) {
        this.router.transitionTo('commitment.title-commitment.index', titleCommitment.id);
    }

    @action
    async onSave() {
        await this.save.perform();
    }

    save = task(this, async () => {
        if (this.args.changeset.get('reviewedWithUser')) {
            this.args.changeset.set('understoodByUser', true);
        }
        await this.args.changeset.save();
        const titleCommitment = await this.args.changeset.get('titleCommitment');
        this.router.transitionTo('commitment.title-commitment.index', titleCommitment.id);
    });

    get saveDisabled(): boolean {
        if (this.args.changeset.get('messageSent')) {
            return false;
        } else if (this.args.changeset.get('manualResponseNote') && this.args.changeset.get('reviewedWithUser')) {
            return false;
        }
        return true;
    }

    get actors(): string[] {
        return this.args.actors.map((actor) => {
            if (actor.givenName && actor.familyName) {
                return `${actor.givenName} ${actor.familyName}`;
            } else {
                return  actor.email;
            }
        }) as string[];
    }
}
