import Component from '@glimmer/component';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { tracked } from '@glimmer/tracking';

enum ALLOWED_TYPES {
    DATE = 'date',
    DATETIME_LOCAL = 'datetime-local',
    EMAIL = 'email',
    TEL = 'tel',
    TEXT = 'text',
    TIME = 'time',
    PASSWORD = 'password',
    URL = 'url'
}

interface EmporaTextArgs {
    type?: ALLOWED_TYPES;
    label?: string;
}

export default class EmporaText extends Component<EmporaTextArgs> {
    get uniqueId() {
        return `${guidFor(this)}`;
    }

    @tracked
        passwordOverride = false;

    @tracked
        passwordOverrideToggled = false;

    @tracked
        firstBlurOccurred = false;

    get type(): ALLOWED_TYPES {
        if (
            this.args.type &&
            (Object.values(ALLOWED_TYPES) as ALLOWED_TYPES[]).includes(
                this.args.type
            )
        ) {
            return this.args.type;
        } else {
            return ALLOWED_TYPES.TEXT;
        }
    }

    get passwordOverrideType(): ALLOWED_TYPES {
        if (this.type === ALLOWED_TYPES.PASSWORD && this.passwordOverride) {
            return ALLOWED_TYPES.TEXT;
        } else {
            return this.type;
        }
    }

    @action
    togglePasswordOverride(): void {
        this.passwordOverride = !this.passwordOverride;
        this.passwordOverrideToggled = true;
    }

    @action
    setFirstBlurOccurred(){
        if (!this.firstBlurOccurred && this.passwordOverride && !this.passwordOverrideToggled) {
            this.togglePasswordOverride();
        }

        this.firstBlurOccurred = true;
    }
}
