import Model, { attr } from '@ember-data/model';
import { luxonDateFormat } from 'admin/helpers/luxon-date-format';

export default abstract class DocumentModel extends Model {
    @attr('string')
        name!: string;

    @attr('string')
        type?: string;

    @attr('string')
        displayType?: string;

    @attr('string')
        path?: string;

    @attr('string')
        filetype?: string;

    @attr('string')
        presignedUrl?: string;

    @attr('string')
        presignedDownloadUrl?: string;

    @attr('boolean')
        canDelete?: boolean;

    @attr('date')
        uploadedAt?: Date;

    file?: File;

    get displayName(): string {
        let displayName = this.name;
        if (this.uploadedAt) {
            displayName += ` - ${luxonDateFormat([this.uploadedAt, 'M/d/yy h:mm a ZZZZ'])}`;
        }
        return displayName;
    }
}

export enum DocumentType {
    '1003_type' = '1003',
    '1031_instructions' = '1031_instructions',
    assignment_contract = 'assignment_contract',
    bankruptcy = 'bankruptcy',
    buyer_wire_instructions = 'buyer_wire_instructions',
    closing = 'closing',
    closing_protection_letter = 'closing_protection_letter',
    commission_agreement = 'commission_agreement',
    commitment = 'commitment',
    community_association_disclosure = 'community_association_disclosure',
    contract_addendum = 'contract_addendum',
    contract_extension = 'contract_extension',
    contract_termination = 'contract_termination',
    court_order = 'court_order',
    credit_report = 'credit_report',
    curative_clearance = 'curative_clearance',
    death_certificate = 'death_certificate',
    deed = 'deed',
    disbursement_authorization = 'disbursement_authorization',
    divorce_document = 'divorce_document',
    drivers_license = 'drivers_license',
    due_diligence_extension_amendment = 'due_diligence_extension_amendment',
    earnest_money = 'earnest_money',
    emd_receipt = 'emd_receipt',
    emd_refund_wire_confirmation = 'emd_refund_wire_confirmation',
    entity_document = 'entity_document',
    estate_plan = 'estate_plan',
    final_settlement_statement = 'final_settlement_statement',
    funding_receipt = 'funding_receipt',
    hazard_insurance = 'hazard_insurance',
    hoa_articles_of_incorporation = 'hoa_articles_of_incorporation',
    hoa_budget = 'hoa_budget',
    hoa_by_laws = 'hoa_by_laws',
    hoa_by_laws_amendment = 'hoa_by_laws_amendment',
    hoa_ccr_amendment = 'hoa_ccr_amendment',
    hoa_ccr = 'hoa_ccr',
    hoa_certificate_of_insurance = 'hoa_certificate_of_insurance',
    hoa_closing_statement_letter = 'hoa_closing_statement_letter',
    hoa_estoppel = 'hoa_estoppel',
    hoa_report = 'hoa_report',
    hoa_resale_certification = 'hoa_resale_certification',
    home_warranty_policy = 'home_warranty_policy',
    indemnity_letter = 'indemnity_letter',
    invoice = 'invoice',
    lease = 'lease',
    lender_documents = 'lender_documents',
    lenders_policy = 'lenders_policy',
    lien_search = 'lien_search',
    management_fee_wire_confirmation = 'management_fee_wire_confirmation',
    mls_listing_sheet = 'mls_listing_sheet',
    onboarding = 'onboarding',
    other = 'other',
    owners_policy = 'owners_policy',
    payoff = 'payoff',
    payoff_authorization = 'payoff_authorization',
    point_of_sale_inspection = 'point_of_sale_inspection',
    power_of_attorney = 'power_of_attorney',
    preliminary_settlement_statement = 'preliminary_settlement_statement',
    property_taxes = 'property_taxes',
    purchase_contract = 'purchase_contract',
    recording_receipt = 'recording_receipt',
    release = 'release',
    rent_roll = 'rent_roll',
    security_instrument = 'security_instrument',
    seller_disclosure = 'seller_disclosure',
    survey = 'survey',
    subject_to = 'subject_to',
    termite_letter = 'termite_letter',
    transmittal_form = 'transmittal_form',
    underwriter_approval = 'underwriter_approval',
    utilities_form = 'utilities_form',
    utility_bill = 'utility_bill',
    wire_instructions = 'wire_instructions'
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        document: DocumentModel;
    }
}
