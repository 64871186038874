import { DateTime } from 'luxon';
import { helper } from '@ember/component/helper';

/**
 * Date format tokens can be found here: https://moment.github.io/luxon/index.html#/formatting?id=table-of-tokens
 */
export function luxonDateFormat(params: [Date, string, string?]) {
    const [date, format, timezone] = params;
    if (timezone) {
        return DateTime.fromJSDate(date).setZone(timezone).toFormat(format);
    } else {
        return DateTime.fromJSDate(date).toFormat(format);
    }
}

export default helper(luxonDateFormat);
