import BaseRoute from 'admin/routes/base-route';
import Store from '@ember-data/store';
import Transition from '@ember/routing/-private/transition';
import window from 'ember-window-mock';
import { railsUrl } from 'admin/helpers/rails-url';
import { inject as service } from '@ember/service';

export default class Deal extends BaseRoute {
    @service
    declare store: Store;

    async model({ deal_id }: { deal_id: string }, transition?: Transition) {
        const routeKey = transition?.to.name as string;
        if (!excludedRoutesFromRedirect.some((route) => routeKey.startsWith(route))) {
            window.location.href = railsUrl([`deals/${deal_id}`]);
        }
        return await this.store.findRecord('deal', deal_id);
    }
}

export const excludedRoutesFromRedirect = [
    'deal.deal-roles'
];
