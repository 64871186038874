import Analytics from 'admin/services/analytics';
import BaseRoute from 'admin/routes/base-route';
import CommitmentTitleCommitmentReview, { SharingMethodKind } from 'admin/models/commitment-title-commitment-review';
import Store from '@ember-data/store';
import Transition from '@ember/routing/-private/transition';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class Review extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare analytics: Analytics;

    async model({ title_commitment_review_id }: { title_commitment_review_id: string }) {
        const titleCommitmentReview = await this.store.findRecord('commitment-title-commitment-review', title_commitment_review_id);
        titleCommitmentReview.set('sharingMethod', SharingMethodKind.portal);

        const titleCommitment = await titleCommitmentReview.titleCommitment;
        const actors = await this.store.query('actor', {
            deal_id: titleCommitment?.dealId,
            role: titleCommitmentReview.dealRole
        });

        return {
            titleCommitmentReview,
            actors: actors.toArray()
        };
    }

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Title Commitment / Review');
    }

    @action
    willTransition(transition: Transition) {
        if (!transition.to.name.includes(this.routeName)) {
            const model = this.modelFor(this.routeName) as any;
            (model.titleCommitmentReview as CommitmentTitleCommitmentReview).rollbackAttributes();
        }
    }
}