import Controller from '@ember/controller';
import { default as CommitmentTitleCommitmentRoute } from 'admin/routes/commitment/title-commitment';
import { ModelFrom } from 'admin/utils/type-utils';

export default class TitleCommitment extends Controller {
    declare model: ModelFrom<CommitmentTitleCommitmentRoute>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
      'commitment/title-commitment': TitleCommitment;
  }
}
